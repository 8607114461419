import React, { useState, useEffect, useRef } from "react";
import AnimateHeight from "react-animate-height";

import Container from "../../components/Container/container";
import QuantitySelect from "../../components/QuantitySelect/quantity-select";
import ImageSelector from "../../components/ImageSelector/image-selector";
import { Stars } from "../../components/Stars/stars";
import CTA from "../../components/CTA/cta";
import "./product-detail.scss";
import MailSignup from "../MailSignup/mail-signup";
import { ProductTypes } from "../../types";
import ProductBulletPoints from "../ProductBulletPoints";
import { useIsMobile } from "../../utils/use-is-mobile";
import { useInventory } from "../../hooks/useInventory";
import { useTrackCart } from "../../hooks/useTrackCart";
import { useReviews } from "../../hooks/useReviews";
import { currencySymbols } from "../../data/currency";
import { SuggestedItems } from "../SuggestedItems/SuggestedItems";
import { cartHasShippingProtection } from "../Cart/shipping-protection";
import jackOne from "../../assets/halloween-1.svg";
import jackTwo from "../../assets/halloween-2.svg";
import jackThree from "../../assets/halloween-3.svg";
import web1 from "../../assets/halloween-4.svg";
import halloween5 from "../../assets/halloween-5.svg";
import ghost from "../../assets/halloween-6-1.svg";
import { HalloweenInfo } from "../Halloween/halloween";

export const ImageFixedState = {
	TOP: "top",
	BOTTOM: "bottom",
	FIXED: "fixed",
};

const vipProducts = [
	"Tearrible - Tiny",
	"Tearrible - Medium",
	"Tearrible - XL",
];

const regularVariants = [
	"Standard",
	"No Squeaker",
	"Limited Edition",
	"Medium",
	"Large",
	// "No Squeaker",
	// "Removable Squeaker / Vibrant Green",
	// "Removable Squeaker / Original Purple",
	// "Removable Squeaker / Royal Blue",
	// "Removable Squeaker / Cuddly Blue",
	// "Removable Squeaker / Hot Pink",
	// "Removable Squeaker / Happy Yellow",
	// "No Squeaker / Vibrant Green",
	// "Squeaker / Happy Yellow",
	// "No Squeaker / Happy Yellow",
	// "Standard / Royal Blue",
	// "Squeaker / Royal Blue",
	// "No Squeaker / Royal Blue",
	// "Vibrant Green",
	// "Original Purple",
	// "Cuddly Blue",
	// "Royal Blue",
	// "Hot Pink",
	// "Mummy",
	// {
	// 	title: "Squeaker / Happy Yellow",
	// 	sizes: ["tiny"],
	// },
	// {
	// 	title: "Squeaker / Royal Blue",
	// 	sizes: ["tiny"],
	// },
	{
		title: "No Squeaker / Happy Yellow",
		sizes: ["tiny"],
	},
	{
		title: "No Squeaker / Royal Blue",
		sizes: ["tiny"],
	},
	{
		title: "Removable Squeaker / Happy Yellow",
		sizes: ["medium"],
	},
	{
		title: "Removable Squeaker / Cuddly Blue",
		sizes: ["medium"],
	},
	// {
	// 	title: "Removable Squeaker / Vibrant Green",
	// 	sizes: ["medium"],
	// },
	{
		title: "Removable Squeaker / Happy Yellow",
		sizes: ["xl"],
	},
	{
		title: "Removable Squeaker / Royal Blue",
		sizes: ["xl"],
	},
	{
		title: "Cyan",
		sizes: ["stingray"],
	},
	// {
	// 	title: "Pretty Pink",
	// 	sizes: ["stingray"],
	// },
	{
		title: "Bright Purple",
		sizes: ["stingray"],
	},
	{
		title: "Happy Yellow",
		sizes: ["stingray"],
	},
	// {
	// 	title: "Cyan",
	// 	sizes: ["octopus"],
	// },
	// {
	// 	title: "Bright Purple",
	// 	sizes: ["octopus"],
	// },
	{
		title: "Pretty Pink",
		sizes: ["octopus"],
	},
	{
		title: "Happy Yellow",
		sizes: ["octopus"],
	},
	{
		title: "Cyan",
		sizes: ["hammerhead:shark"],
	},
	{
		title: "Bright Purple",
		sizes: ["hammerhead:shark"],
	},
	// {
	// 	title: "Pretty Pink",
	// 	sizes: ["hammerhead:shark"],
	// },
	{
		title: "Happy Yellow",
		sizes: ["hammerhead:shark"],
	},
];

const vipVariants = [
	// "Removable Squeaker / Royal Blue",
	"Removable Squeaker / Happy Yellow",
	"Removable Squeaker / Cuddly Blue",
	"Standard / Royal Blue",
	// "No Squeaker / Royal Blue",
	"Winter Tearrors",
	{
		title: "With Squeaker / Small",
		sizes: ["snowball"],
	},
	{
		title: "With Squeaker / Medium",
		sizes: ["snowball"],
	},
	{
		title: "With Squeaker / XL",
		sizes: ["snowball"],
	},
	{
		title: "Without Squeaker / Small",
		sizes: ["snowball"],
	},
	{
		title: "Without Squeaker / Medium",
		sizes: ["snowball"],
	},
	{
		title: "Without Squeaker / XL",
		sizes: ["snowball"],
	},
];

const auVariants = [
	{
		title: "Happy Yellow",
		sizes: ["tiny"],
	},
	{
		title: "Vibrant Green",
		sizes: ["medium"],
	},
	{
		title: "Happy Yellow",
		sizes: ["medium"],
	},
	{
		title: "Royal Blue",
		sizes: ["xl"],
	},
	{
		title: "Happy Yellow",
		sizes: ["xl"],
	},
	"Standard",
];

export const colorOrder = [
	"Pretty Pink",
	"Vibrant Green",
	"Cyan",
	"Bright Purple",
	"Hot Pink",
	"Vibrant Green",
	"Royal Blue",
	"Original Purple",
	"Cuddly Blue",
	"Happy Yellow",
	"Mummy",
];

export const sizeOrder = ["5 oz", "10 oz", "15 oz", "10 Pack"];

const productTypesWithDescription = [
	ProductTypes.TEARRIBLE,
	ProductTypes.HALLOWEEN,
	ProductTypes.TRICK_TREAT,
	ProductTypes.UNDERWORLD,
	ProductTypes.BONEYARD,
	ProductTypes.SHIPPING_PROTECTION,
];

function Description({ info, productType, html, size }) {
	if (info?.secondaryHeadline?.[size]) {
		return (
			<div className="product-detail__description">
				{info?.secondaryHeadline?.[size]}
			</div>
		);
	}

	if (html && productTypesWithDescription.includes(productType)) {
		return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
	}

	return null;
}

const ProductDetail = ({
	data,
	values,
	handleChange,
	handleSubmit,
	isSubmitting,
	productIndex = 0,
	geoLocation,
	options,
	imageIndex,
	size,
	productType,
	setFieldValue,
	scrollToReviews,
	...props
}) => {
	const domain =
		process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

	const containerRef = useRef();
	const imageContainerRef = useRef();
	const selectorRef = useRef();
	const [selectedImage, setSelectedImage] = useState(1);
	const [imageFixed, setImageFixed] = useState(ImageFixedState.TOP);
	const [imageWidth, setImageWidth] = useState(0);
	const { isMobile } = useIsMobile();
	const { trackAddToCart } = useTrackCart();

	const handleScroll = () => {
		if (!selectorRef.current) return;
		const { height } = selectorRef.current?.getBoundingClientRect();
		const imageRect = imageContainerRef.current.getBoundingClientRect();
		const containerRect = containerRef.current.getBoundingClientRect();

		const { top, width } = imageRect;
		const { height: containerHeight, top: containerTop } = containerRect;

		if (containerTop > 0) {
			setImageFixed(ImageFixedState.TOP);
			setImageWidth(0);
		} else if (Math.abs(containerTop) + height > containerHeight - 36) {
			setImageFixed(ImageFixedState.BOTTOM);
			setImageWidth(0);
		} else {
			setImageFixed(ImageFixedState.FIXED);
			setImageWidth(width);
		}
	};

	useEffect(() => {
		if (!isMobile) {
			window.addEventListener("scroll", handleScroll);
		} else {
			window.removeEventListener("scroll", handleScroll);
		}

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [isMobile]);

	useEffect(() => {
		setSelectedImage(imageIndex);
	}, [imageIndex]);

	let productArray = data.allShopifyProduct.edges;

	const product =
		Array.isArray(productArray) && productArray[productIndex].node;

	// Add halloween
	useEffect(() => {
		const bodyElement = document.querySelector("body");
		console.log(product.tags, bodyElement);
		if (product.tags.includes("Halloween")) {
			if (bodyElement) {
				bodyElement.classList.add("halloween");
			}
		}
	}, [product]);

	const { data: inventoryData } = useInventory(product?.handle);
	const { data: reviews } = useReviews(product?.legacyResourceId);

	// Filter out Special Offer.
	let productVariants = product?.variants
		.filter((item) => {
			return (
				item.title !== "Special Offer" && !item.title.includes("HIDE")
			);
		})
		.filter((item) => {
			if (product?.productType !== "Tearrible") {
				return true;
			}

			// Display all AU products.
			if (domain === "tearriblesau.myshopify.com") {
				return true;
			}

			const vipIncluded = vipVariants.find((curr) => {
				if (typeof curr === "string") {
					return item.title === curr;
				}

				if (curr.sizes.includes(size)) {
					return item.title === curr.title;
				}

				return false;
			});

			const variantIncluded = regularVariants.find((curr) => {
				if (typeof curr === "string") {
					return item.title === curr;
				}

				if (curr.sizes.includes(size)) {
					return item.title === curr.title;
				}

				return false;
			});

			return variantIncluded || (vipIncluded && props.preOrderAuthorized);
		})
		// AU only filter
		.filter((item) => {
			if (domain !== "tearriblesau.myshopify.com") {
				return true;
			}

			if (product?.productType !== "Tearrible") {
				return true;
			}

			const variantIncluded = auVariants.find((curr) => {
				if (typeof curr === "string") {
					return item.title === curr;
				}

				if (curr.sizes.includes(size)) {
					return item.title === curr.title;
				}

				return false;
			});

			return variantIncluded;
		})
		// Filter out body parts.
		.filter((item) => {
			if (product?.productType !== "Parts") {
				return true;
			}

			if (item.title === "Body") {
				return false;
			}

			return true;
		});

	// Add inventory information.
	if (inventoryData) {
		let variantInventory =
			inventoryData.productByHandle &&
			inventoryData.productByHandle.variants &&
			inventoryData.productByHandle.variants.edges;

		variantInventory =
			variantInventory &&
			variantInventory.map(({ node }) => {
				const legacyResourceId = node.id.replace(
					"gid://shopify/ProductVariant/",
					"",
				);
				const newNode = { legacyResourceId, ...node };
				return newNode;
			});

		productVariants = productVariants?.map((item) => {
			const inventoryInfo =
				variantInventory &&
				variantInventory.find((variant) => {
					return item.legacyResourceId === variant.legacyResourceId;
				});
			if (inventoryInfo) {
				inventoryInfo.tracked =
					inventoryInfo.inventoryItem &&
					inventoryInfo.inventoryItem.tracked;
			}
			const info = { ...item, ...inventoryInfo };
			return info;
		});
	}

	// Establish current selection.
	let currentVariant = productVariants?.find((variant) => {
		return variant.id === values.id;
	});

	// Check current variant is not sold out.
	useEffect(() => {
		if (
			(currentVariant && !currentVariant?.availableForSale) ||
			(currentVariant?.inventoryQuantity <= 0 && currentVariant.tracked)
		) {
			const nextAvailable = productVariants.find((item) => {
				return (
					item.availableForSale &&
					item.tracked &&
					item.inventoryQuantity > 0
				);
			});

			if (nextAvailable) {
				setFieldValue("id", nextAvailable.id);
			} else {
				setFieldValue("id", null);
			}
		}
	}, [inventoryData]);

	useEffect(() => {
		const isCorrectOptions = currentVariant?.selectedOptions?.reduce(
			(acc, { name, value }) => {
				if (
					!props?.selectedOptions[name] ||
					value !== props?.selectedOptions[name]
				) {
					return false;
				}

				return acc;
			},
			true,
		);

		if (isCorrectOptions === false) {
			props?.setOptions(currentVariant.selectedOptions);
		}
	}, [values.id]);

	if (!product) {
		return null;
	}

	// Hide if product is marked as hidden or no variants are available.
	const hide =
		product.tags.includes("Hide") ||
		(!currentVariant && !productVariants.length);

	if (hide) {
		return <div className="product-detail"></div>;
	}

	if (!currentVariant) {
		currentVariant = productVariants[0];
		setFieldValue(`id`, currentVariant.id);
	}

	let { price, compareAtPrice, inventoryQuantity, title, tracked } =
		currentVariant;

	const currencyCode =
		currentVariant?.presentmentPrices?.[0]?.price?.currencyCode;
	const currency = currencySymbols.get(currencyCode);

	// REMOVE AFTER PRE-ORDER
	const preOrder = product.tags.includes("Pre Order");

	// Check if at least one item is available for sale
	let productAvailable =
		productVariants.reduce((acc, variant) => {
			if (acc) return acc;
			return variant.availableForSale;
		}, false) && !product.tags.includes("Sold Out");

	if (currentVariant?.availableForSale === false) {
		productAvailable = false;
	}

	const images = product.media
		.map((item) => {
			return item.image;
		})
		.filter((item) => item)
		.filter((item) => {
			return item.altText.includes("DO_NOT_DISPLAY") === false;
		})
		.filter((node) => {
			if (!product?.featuredImage?.localFile?.childImageSharp?.fluid) {
				return true;
			}

			if (node.originalSrc === product.featuredImage?.originalSrc) {
				return false;
			}

			return true;
		})
		.filter((item) => {
			if (!props.preOrderAuthorized && item.altText.includes("VIP")) {
				return false;
			}

			return true;
		})
		.filter((item) => {
			if (size === "xl") {
				const filterColors = [
					"Hot Pink",
					"Original Purple",
					"Cuddly Blue",
				];
				const colorMatches = filterColors.map((color) => {
					return item.altText.includes(color);
				});
				return !colorMatches.includes(true);
			}

			if (size === "medium") {
				const filterColors = ["Mummy", "Royal Blue"];
				const colorMatches = filterColors.map((color) => {
					return item.altText
						.toLowerCase()
						.includes(color.toLowerCase());
				});
				return !colorMatches.includes(true);
			}

			if (size === "octopus") {
				const filterColors = ["Mummy", "Royal Blue", "Vibrant Green"];
				const colorMatches = filterColors.map((color) => {
					return item.altText
						.toLowerCase()
						.includes(color.toLowerCase());
				});
				return !colorMatches.includes(true);
			}

			return true;
		});
	// .sort((a, b) => {
	// 	const colorIndexA = colorOrder.findIndex((item) => {
	// 		return a?.altText?.includes(item);
	// 	});
	// 	const colorIndexB = colorOrder.findIndex((item) => {
	// 		return b?.altText?.includes(item);
	// 	});
	//
	// 	if (colorIndexA > colorIndexB) return 1;
	// 	if (colorIndexA < colorIndexB) return -1;
	// 	return 0;
	// });

	if (product?.featuredImage?.localFile?.childImageSharp?.fluid) {
		const hasFeatured = images.find((item) => {
			const imageSrc = item.originalSrc.split("?")[0];
			const featuredSrc =
				product.featuredImage?.originalSrc.split("?")[0];
			return imageSrc === featuredSrc;
		});

		if (!hasFeatured) {
			images.unshift(product.featuredImage);
		}
	}

	let ctaTitle = "ADD TO CART";

	if (!productAvailable && tracked && inventoryQuantity <= 0) {
		ctaTitle = "SOLD OUT";
	}

	if (preOrder) {
		ctaTitle = "PRE ORDER";
	}

	// eslint-disable-next-line
	const renderOptions = (variant) => {
		let { availableForSale } = variant;
		let radioClasses = ["product-detail__squeaker-option"];

		const handleSelection = (event) => {
			const selectedID = event?.target?.value;

			if (!selectedID) {
				return null;
			}

			// Change image selected.
			if (selectedID && product?.variants) {
				const currentVariant = productVariants.find((variant) => {
					return variant.id === selectedID;
				});

				if (currentVariant?.image?.originalSrc) {
					const currentVariantImageUrl = new URL(
						currentVariant?.image?.originalSrc,
					);

					const imageIndex = images?.findIndex((image) => {
						if (!image.originalSrc) return false;
						const imageUrl = new URL(image.originalSrc);
						return (
							currentVariantImageUrl?.pathname ===
							imageUrl?.pathname
						);
					});

					if (imageIndex && imageIndex !== -1) {
						setSelectedImage(imageIndex);
					}
				}
			}

			// Set value on form.
			setFieldValue("id", event.target.value);
		};

		const title = variant?.title.replace("Removable Squeaker / ", "");

		return (
			<div className={radioClasses.join(" ")} key={variant.id}>
				<label>
					<input
						className="product-detail__radio"
						type="radio"
						name="id"
						value={variant.id}
						onChange={availableForSale ? handleSelection : null}
						checked={values.id === variant.id}
					/>
					<span
						className={
							!availableForSale
								? "product-detail__squeaker-option--sold-out"
								: ""
						}
					>
						{title}
					</span>
					<div className="product-detail__check" />
				</label>
			</div>
		);
	};

	const addToCart = async (event) => {
		const productData = {
			id: product?.legacyResourceId,
			sku: currentVariant?.sku,
			variant: currentVariant?.legacyResourceId,
			name: product?.title,
			price: price,
			quantity: values?.quantity,
		};
		trackAddToCart(productData);
		await handleSubmit();
	};

	const preOrderConfirmation = (
		<>
			<div className="product-detail__pre-order-confirmation">
				<div className="product-detail__pre-order-confirmation__title">
					Pre-Order Confirmation
				</div>
				<div className="product-detail__pre-order-confirmation__text">
					This pre-order item is expected to ship by May 22nd.
				</div>
				<div className="product-detail__pre-order-confirmation__text">
					Please note that if you order multiple items, your order
					will be shipped out once all items are available.
				</div>
				<div className="product-detail__pre-order-confirmation__buttons">
					<CTA
						title="Add to Cart"
						onClick={async () => {
							await addToCart();
							props.setPopupComponent(null);
						}}
						styles={{ marginBottom: 0 }}
					/>
					<CTA
						title="Cancel"
						secondary
						onClick={() => {
							console.log("cancel");
							props.setPopupComponent(null);
						}}
						styles={{ marginBottom: 0 }}
					/>
				</div>
			</div>
		</>
	);

	let hideCTA = false;

	if (productType === ProductTypes.SHIPPING_PROTECTION) {
		hideCTA = true;
	}

	const vip = product.tags.includes("VIP");
	if (vip && !props.preOrderAuthorized) {
		return null;
	}

	return (
		<div className="product-detail">
			<Container style={{ width: "100%", maxWidth: "1200px" }}>
				<div className="product-detail__wrapper" ref={containerRef}>
					<div
						className={`product-detail__image ${
							imageFixed === ImageFixedState.BOTTOM
								? "product-detail__image--bottom"
								: ""
						}`}
						ref={imageContainerRef}
					>
						<ImageSelector
							images={images}
							altText={product.title}
							selectedImage={selectedImage}
							setSelectedImage={setSelectedImage}
							setFieldValue={setFieldValue}
							variants={productVariants}
							displayOptions={
								productType !== ProductTypes.GIFT_CARD
							}
							fixed={imageFixed}
							fixedWidth={imageWidth}
							selectorRef={selectorRef}
							isMobile={isMobile}
							preOrder={productAvailable && preOrder}
						/>
					</div>
					<div className="product-detail__info">
						<h2 className="product-detail__title">
							{product.title}
						</h2>
						<div
							className="product-detail__stars"
							// onClick={scrollToReviews}
							style={{ cursor: "pointer" }}
						>
							<Stars reviews={reviews.data} />
						</div>
						{/* TODO: Check if this needs to use dangerouslySetInnerHTML */}
						<Description
							size={size}
							info={props.info}
							productType={productType}
							html={product?.descriptionHtml}
						/>
						<div className="product-detail__divider">
							<img
								src={ghost}
								className="halloween-only product-detail__halloween-ghost"
							/>
						</div>

						{(productType === ProductTypes.TEARRIBLE ||
							productType === ProductTypes.HALLOWEEN) && (
							<ProductBulletPoints
								info={props.info}
								size={size}
								sideBar
								selectedOptions={props.selectedOptions}
								preOrderAuthorized={props.preOrderAuthorized}
							/>
						)}

						{/* Inventory Info */}
						<AnimateHeight
							height={
								inventoryQuantity < 100 || preOrder ? "auto" : 0
							}
							duration={300}
							ease="ease-out"
							animateOpacity={true}
						>
							{tracked &&
							inventoryQuantity &&
							inventoryQuantity < 100 &&
							inventoryQuantity > 0 &&
							!preOrder &&
							productAvailable &&
							productType !== ProductTypes.GIFT_CARD ? (
								<div className="product-detail__lowInventory">
									Only {inventoryQuantity} available.
								</div>
							) : (
								<div></div>
							)}

							{
								/* tracked && inventoryQuantity <= 0 && */
								preOrder &&
								productType !== ProductTypes.GIFT_CARD ? (
									<div className="product-detail__lowInventory">
										<span
											style={{
												fontWeight: "normal",
												marginTop: ".25rem",
												display: "block",
											}}
										>
											Available for pre-order. Expected to
											ship by May 22nd.
										</span>
									</div>
								) : (
									<div></div>
								)
							}

							{
								/* tracked && inventoryQuantity <= 0 && */
								!productAvailable &&
								productType !== ProductTypes.GIFT_CARD ? (
									<div className="product-detail__lowInventory">
										SOLD OUT.
									</div>
								) : (
									<div></div>
								)
							}
						</AnimateHeight>

						{/* Sign up for pre-order if product is not available and not authed for special pre-order */}
						{domain === "tearribles.myshopify.com" &&
							size === "none" && (
								<div className="product-detail__backorder">
									<h4
										style={{
											marginTop: "0",
											color: "#2E50CE",
										}}
									>
										ATTENTION:
									</h4>
									<div>
										Due to high demand, all{" "}
										{product.title.replace(" - ", " ")} are
										sold out
										{/* and are expected to
										be back in stock in{" "}
										<span style={{ fontWeight: "bold" }}>
											by the end of February
										</span> */}
										.
										<br />
										Signup below to be notified when they
										become available.
									</div>
									<MailSignup
										tags={
											size === "virus"
												? ["Virus Notify"]
												: ["XL Notify"]
										}
										list="703e63cb63"
										ctaText="NOTIFY ME"
									/>
								</div>
							)}

						{productType == ProductTypes.PARTS && (
							<div className="product-detail__backorder">
								<h4
									style={{ marginTop: "0", color: "#2E50CE" }}
								>
									ATTENTION:
								</h4>
								<div>
									You are currently viewing the replacement
									parts page. This page is used for ordering
									replacement limbs and extremities.
									<br />
									<br /> If you are looking to purchase the
									full toy you can find it here:{" "}
									<a
										href={`https://www.tearribles.com/product/${product.handle.replace(
											"-replacement-parts",
											"",
										)}`}
									>
										{product.title.replace(
											" - Replacement Parts",
											"",
										)}
									</a>
								</div>
							</div>
						)}

						{/* {preOrder && !props.preOrderAuthorized
                            && <div className="product-detail__backorder">
                                <h4 style={{ marginTop: '0', color: '#2E50CE' }}>ATTENTION:</h4>
                                <div>
                                    Due to high demand, all {product.title.replace(" - ", " ")} orders are currently on back order and are expected to ship <span style={{ fontWeight: 'bold' }}>mid August</span>.<br /><br />
                                    Orders including pre-order items will ship together.
                                </div>
                            </div>
                        } */}

						{/* {productType === ProductTypes.TEARRIBLE &&
							domain === "tearribles.myshopify.com" && (
								<div className="product-detail__backorder">
									<h4
										style={{
											marginTop: "0",
											color: "#2E50CE",
										}}
									>
										ATTENTION:
									</h4>
									<div>
										{preOrder && (
											<>
												All pre-orders are going to ship
												out by the{" "}
												<span
													style={{
														fontWeight: "bold",
														color: "#c40707",
													}}
												>
													second week of January
												</span>
												.<br />
												<br />
											</>
										)}
										Any orders including pre-order items
										will ship at the time the pre-ordered
										items become available.
									</div>
								</div>
							)} */}

						{/* productType === ProductTypes.TEARRIBLE &&
							domain === "tearriblesau.myshopify.com" && (
								<div className="product-detail__backorder">
									<h4
										style={{
											marginTop: "0",
											color: "#2E50CE",
										}}
									>
										ATTENTION:
									</h4>
									<div>
										All pre-orders are going to ship out by
										the{" "}
										<span style={{ fontWeight: "bold" }}>
											end of October
										</span>
										.<br />
										<br />
										Orders including pre-order items will
										ship together.
									</div>
								</div>
							) */}

						{/* <div className="product-detail__backorder">
                            <h4 style={{ marginTop: '0', color: '#2E50CE' }}>HOORAY! FREE STUFF:</h4>
                            <div>
                                Limited Time Only - A roll of biodegradable jasmine scented poop bags included in every order.
                            </div>
                        </div> */}

						{/* Render Options. */}
						{Array.isArray(productVariants) &&
							(productType === ProductTypes.TEARRIBLE ||
								productType === ProductTypes.COMBO) &&
							size !== "snowball" &&
							(productVariants.length > 1 ||
								productVariants[0].title !==
									"Default Title") && (
								<div>
									<h4>Options:</h4>
									{productVariants.map(renderOptions)}
								</div>
							)}

						{!hideCTA && (
							<div className="product-detail__options">
								{Array.isArray(options) &&
									options.map((option) => {
										if (!option.component) {
											return null;
										}

										return (
											<div
												className="product-detail__size-options"
												key={option.name}
											>
												{option.name && (
													<h4>{option.name}</h4>
												)}
												{typeof option.component ===
												`function`
													? option.component(
															{
																...props,
																setSelectedImage,
																setFieldValue,
																images,
															},
															productVariants,
															product.options,
														)
													: option.component}
											</div>
										);
									})}
								<div>
									<h4>Quantity:</h4>
									<QuantitySelect
										name="quantity"
										values={values}
										setFieldValue={setFieldValue}
									/>
								</div>
							</div>
						)}
						{!hideCTA && (
							<div className="product-detail__button-wrapper">
								<div>
									{compareAtPrice &&
										price !== compareAtPrice && (
											<div className="product-detail__compare-price">
												{domain ===
													"tearribles.myshopify.com" &&
													"$"}
												{compareAtPrice}
												{domain ===
													"tearriblesau.myshopify.com" &&
													" AUD"}
											</div>
										)}
									<div className="product-detail__price">
										{currencyCode === "USD" && "$"}
										{price}
									</div>
									{currencyCode && currencyCode !== "USD" && (
										<div className="product-detail__currency">
											{currencyCode}
										</div>
									)}
								</div>
								<CTA
									title={ctaTitle}
									onClick={
										preOrder
											? () =>
													props.setPopupComponent(
														preOrderConfirmation,
													)
											: addToCart
									}
									styles={{
										marginTop: "1rem",
										width: "100%",
										textAlign: "center",
									}}
									loading={isSubmitting}
									disabled={
										!productAvailable && !preOrder
										// || (tracked && inventoryQuantity > 0)
									}
								/>
								<img
									src={jackOne}
									className="halloween-only product-detail__halloween-jack-one"
								/>
								<img
									src={jackTwo}
									className="halloween-only product-detail__halloween-jack-two"
								/>
								<img
									src={jackThree}
									className="halloween-only product-detail__halloween-jack-three"
								/>
								<img
									src={web1}
									className="halloween-only mobile-only product-detail__halloween-web-one"
								/>
							</div>
						)}

						{geoLocation === "US" &&
							!hideCTA &&
							productType !== ProductTypes.WALKIES && (
								<>
									<div className="product-detail__free-shipping">
										<img
											src={halloween5}
											className="halloween-only product-detail__halloween-five"
										/>
										<span>Want FREE Shipping?</span> Spend
										over{" "}
										{domain === "tearriblesau.myshopify.com"
											? "75 AUD"
											: "$50"}
										.
										{/* <span>Want FREE Priority Shipping?</span> Spend $50 or more. Due to COVID-19, Priority Shipping is slower than normal, could take up to 4 days. */}
									</div>
								</>
							)}

						{geoLocation !== "US" &&
							domain === "tearribles.myshopify.com" && (
								<>
									<div className="product-detail__free-shipping">
										<span>International Shipping</span>{" "}
										These toys are shipped from the United
										States. You may be responsible for
										additional VAT and/or duties at the time
										you pick up your order from the carrier.
									</div>
								</>
							)}

						<SuggestedItems
							{...{
								data,
								values,
								handleChange,
								handleSubmit,
								isSubmitting,
								productIndex,
								geoLocation,
								options,
								imageIndex,
								size,
								productType,
								setFieldValue,
								scrollToReviews,
								addToCart,
								currentVariant,
								...props,
							}}
						/>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default ProductDetail;
