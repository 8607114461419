import React from "react";
import Container from "../Container/container";
import MailSignup from "../MailSignup/mail-signup";
import "./halloween.scss";
import { ProductTypes } from "../../types";
import ProductSelect from "../../components/ProductSelect/product-select";

export function HalloweenInfo() {
	// return (
	// 	<div className="halloween-info">
	// 		<Container>
	// 			<div className="halloween-info__wrapper">
	// 				<div className="halloween-info__right">
	// 					<div className="halloween-info__video-wrapper">
	// 						<video
	// 							src="https://pub-31743952077f4d91a1ba149022836b45.r2.dev/voodoo_desktop.mp4"
	// 							className="halloween-info__video"
	// 							disablePictureInPicture
	// 							playsInline
	// 							loop
	// 							autoPlay={true}
	// 							muted={true}
	// 						/>
	// 					</div>
	// 				</div>
	// 				<h2>
	// 					Limited Edition Voodoo!
	// 					<br /> Coming Soon
	// 				</h2>
	// 				<p>
	// 					Signup below to be notified when they become available.
	// 				</p>
	// 				<MailSignup
	// 					tags={["Voodoo Notify"]}
	// 					list="703e63cb63"
	// 					ctaText="Notify Me"
	// 				/>
	// 			</div>
	// 		</Container>
	// 	</div>
	// );

	return (
		<div className="halloween-info">
			<Container>
				<div className="halloween-info__wrapper">
					<div className="halloween-info__right">
						<div className="halloween-info__video-wrapper">
							<video
								src="https://tearribles.co.uk/videos/voodoo_desktop.mp4"
								className="halloween-info__video"
								disablePictureInPicture
								playsInline
								loop
								autoPlay={true}
								muted={true}
							/>
						</div>
					</div>
					<div className="halloween-info__left">
						<h2>
							Why let toys rest in peace, when they can rest in
							peices?
						</h2>
						<p>
							Many mortals believe that a human created
							Tearribles, but the legend has it that they were
							summoned by the magic spells cast by two pups that
							used a voodoo doll to bring their favorite toy back
							to life.
						</p>
						<p>
							Quantities are extremely limited, but as a VIP you
							and your pup can get your hands & paws on one before
							they become available to the general public.
						</p>
					</div>
				</div>
			</Container>
			<div className="halloween-info__headline">
				<h2>
					Trick or Treat? <br className="mobile-only" />
					Why not both?
				</h2>
			</div>
		</div>
	);
}

export function HalloweenCombos(props) {
	const halloweenCollection = props.data.allShopifyCollection.nodes?.find(
		(item) => {
			console.log(item);
			return item.handle === "halloween-combos";
		},
	);

	if (!halloweenCollection) {
		return null;
	}

	const halloweenCombos = halloweenCollection.products.filter((node) => {
		console.log(node);
		if (
			node.tags.includes("Halloween")
			// && !node.tags.includes("Hide")
		) {
			return true;
		}

		return false;
	});

	console.log(halloweenCombos);

	return (
		<div className="halloween-info">
			<Container>
				<ProductSelect
					{...props}
					title="Halloween Combos"
					displayPrice={false}
					data={{
						allShopifyCollection: {
							edges: [
								{
									node: {
										products: halloweenCombos,
									},
								},
							],
						},
					}}
				/>
			</Container>
		</div>
	);
}
