import React from "react";
import "video-react/dist/video-react.css";

export default {
	description: [
		"Industrial-strength hook and loop fastener (aka “Velcro”) on limbs provides enough resistance to simulate that of a regularly-stitched toy, and allows your dog to experience the satisfaction of “dismembering their prey” by pulling the limbs off the toy, without actually destroying it.",
		<p>
			Tearribles are interactive toys that will provide you and your dog
			with hours of fun, bonding time, and give your dog the exercise and
			mental stimulation he/she needs to minimize destructive behaviors
			which result from boredom and lack of exercise.{" "}
			<span style={{ fontWeight: "bold" }}>
				Tearribles are not a chew toy.
			</span>
		</p>,
	],
	bulletPoints: [
		"Hook and loop (aka ‘Velcro') pockets allow limbs to be ripped off by the dog, and re-attached by owner, for hours of continuous play",
		"Lined with strong mesh on the inside to provide extra durability and support",
		"Triple-stitching allows toys to be pulled, tugged, and dismembered repeatedly",
		"Perfect for an interactive game of fetch, or tug",
		{
			exclude: ["virus", "tiny"],
			point: "Squeaker is secured inside of a special pocket, preventing it from sliding around the toy during play",
		},
		"Super soft, high quality plush makes for a great comfort toy, or a cuddle buddy once the play is done",
	],
	firehoseBulletPoints: [
		"Hook and loop (aka ‘Velcro') pockets allow appendages to be ripped off by the dog, and re-attached by the owner, for hours of continuous play",
		"Sturdy canvas material (aka firehose material) for extra durability and support",
		"Secure stitching allowes toys to be pulled, tugged, and dismembered repeatedly",
		"Perfect for an interactive game of fetch, or tug",
		"Removable squeaker secured inside of a special pocket, preventing it from sliding around the toy during play",
		"Super-stuffed insides make it a great comfort toy, or a cuddle buddy once the play is done",
	],
	perks: [
		"Machine washable",
		"Tearribles are voluntarily tested to meet or exceed even childrens’ toys standards",
	],
	sizeDescription: {
		tiny: '5" tall, with detachable legs, eyes, and tail. ',
		medium: '9" tall, with detachable legs, arms, ears, and tail. ',
		voodoo: '14" tall, with detachable head, legs, arms, and ears. ',
		xl: '14" tall, with detachable legs, arms, ears, and tail. ',
		maximus:
			'17" tall, with detachable legs, arms, ears, and head. Crinkle paper legs. A back pocket large enough to fit a tennis ball (or treats).',
		virus: `10” in diameter, with 16 detachable tentacles. No squeaker option only.`,
		candycane:
			'5" tall, with detachable legs, eyes, and tail. Christmas edition of our Tearrible Tiny.',
		gingerbread: '10" tall, with detachable head, legs, arms, and tail.',
		angel: '14" tall, with 24" wing span. Detachable head, halo, wings, and tail. "Crinkle paper" inside wings for extra fun.',
		stingray:
			'14" tall, 3 detachable fins, 1 detachable tail, and 4 removable organs.',
		octopus:
			'14" in circumference, with 8 detachable tentacles, and 2 detachable ears.',
		"hammerhead:shark":
			'14" long, 3 detachable fins, 1 detachable tail. Stationary squeaker.',
		"venus:flytrap":
			'12" tall medium, and 16" tall large with detachable legs, arms, head, tail and ears. Removable squeaker and internal organs.',
	},
	secondaryHeadline: {
		tiny: "Perfect for small dogs!",
		medium: "Perfect for small and medium dogs!",
		xl: "Perfect for medium and large dogs!",
		voodoo: "Perfect for all dogs!",
		stingray: (
			<>
				Perfect for medium and large dogs!
				<br />
				Removable/optional squeaker.
			</>
		),
		octopus: "Perfect for medium and large dogs!",
		"hammerhead:shark": "Perfect for medium and large dogs!",
		gingerbread: "Perfect for medium and large dogs!",
		candycane: "Perfect for small dogs!",
		angel: "Well suited for giant dogs!",
	},
};
