import React from "react";
import "video-react/dist/video-react.css";

export const trickOrTreat = {
	description: [
		<h5 style={{ marginTop: "16px" }}>TEARRIBLE VOODOO</h5>,
		"14” tall, 2 detachable arms, 2 detachable legs, 2 detachable ears, and detachable head. Removable squeaker.",
		"Industrial-strength hook and loop fastener (aka “Velcro”) on limbs provides enough resistance to simulate that of a regularly-stitched toy, and allows your dog to experience the satisfaction of “dismembering their prey” by pulling the limbs off the toy, without actually destroying it.",
		<p>
			Tearribles are interactive toys that will provide you and your dog
			with hours of fun, bonding time, and give your dog the exercise and
			mental stimulation he/she needs to minimize destructive behaviors
			which result from boredom and lack of exercise.{" "}
			<span style={{ fontWeight: "bold" }}>
				Tearribles are not a chew toy.
			</span>
		</p>,
		<h5>PB&J TREATS</h5>,
		<p>
			Introducing our{" "}
			<span style={{ fontWeight: "bold" }}>
				Peanut Butter & Jelly Training Treats
			</span>{" "}
			by our sister company, Baloomba Treats—a fang-tastic fusion of two
			classic flavors that dogs simply can’t resist! Crafted with
			all-natural ingredients and a sprinkle of Halloween magic, these
			treats promise to make training and play sessions both fun and
			flavorful.
		</p>,
		<h5>CHICKIE NUGS</h5>,
		<p>
			Get ready for a howling good time with Chickie Nugs—the ultimate
			celebration of happiness for your furry friend this Halloween!
			Artfully crafted in small batches, these training treats feature a
			ghostly blend of real chicken, chicken gizzards, and oats—nothing
			but pure, tail-wagging goodness. Because your dog deserves the VIP
			treatment, even during spooky season!
		</p>,
		<p>
			<span style={{ fontWeight: "bold" }}>
				No Mystery Ingredients—Just Paw-licking Goodness!
			</span>
			<br />
			{`We’re all about keeping it real! Our Chicken & Oat Training Treats
			come with no tricks—just wholesome ingredients. No fillers or
			additives, just high-quality lean protein from USA-raised chicken,
			supporting your dog's training journey with every tasty bite.`}
		</p>,
		<h5>BEEF CHEEK CHIPS</h5>,
		<p>
			This Halloween season, unleash the spooky goodness directly from the
			boneyard:{" "}
			<span style={{ fontWeight: "bold" }}>
				Baloomba Beef Cheek Chips
			</span>
			—the perfect treat for medium and large chewers ready to sink their
			teeth into something delightfully ghoulish!
		</p>,
	],
	bulletPoints: [],
	sizeDescription: {
		trick_treat: (
			<>
				<p>
					Included: Tearrible Tiny and Tearrible Medium, and a 10 pack
					of Baloomba 6 inch Thin Collagen Sticks. This combo is the
					perfect tool to help you turn your "super chewer" into a dog
					that knows the difference between playtime and downtime.
				</p>
				<p>
					Tearrible Mediums provide you with hours of playtime with
					your pup, while Collagen Stix give your pup something
					natural and digestible to chew on when you don't have time
					to play (or you just want to catch up on the last episode of
					your favorite show in peace).
				</p>
			</>
		),
	},
	secondaryHeadline: {
		trick_treat: "Perfect for Medium and Large Dogs!",
	},
};

export const boneyard = {
	description: [
		<h5 style={{ marginTop: "16px" }}>TEARRIBLE VOODOO</h5>,
		"14” tall, 2 detachable arms, 2 detachable legs, 2 detachable ears, and detachable head. Removable squeaker.",
		"Industrial-strength hook and loop fastener (aka “Velcro”) on limbs provides enough resistance to simulate that of a regularly-stitched toy, and allows your dog to experience the satisfaction of “dismembering their prey” by pulling the limbs off the toy, without actually destroying it.",
		<p>
			Tearribles are interactive toys that will provide you and your dog
			with hours of fun, bonding time, and give your dog the exercise and
			mental stimulation he/she needs to minimize destructive behaviors
			which result from boredom and lack of exercise.{" "}
			<span style={{ fontWeight: "bold" }}>
				Tearribles are not a chew toy.
			</span>
		</p>,
		<h5>BALOOMBA BULLY STICK BRAIDS</h5>,
		<p>
			This Halloween, give your furry friend a spine-tingling delight with
			our Baloomba Bully Stick Braids! Ethically sourced and packed with
			goodness, these treats are the perfect way to keep ghouls at bay and
			destructive chewing in check.
		</p>,
		<h5>BEEF CHEEK CHIPS</h5>,
		<p>
			This Halloween season, unleash the spooky goodness directly from the
			boneyard:{" "}
			<span style={{ fontWeight: "bold" }}>
				Baloomba Beef Cheek Chips
			</span>
			—the perfect treat for medium and large chewers ready to sink their
			teeth into something delightfully ghoulish!
		</p>,
	],
	bulletPoints: [],
	sizeDescription: {
		boneyard: (
			<>
				<p>
					Included: Tearrible Tiny and Tearrible Medium, and a 10 pack
					of Baloomba 6 inch Thin Collagen Sticks. This combo is the
					perfect tool to help you turn your "super chewer" into a dog
					that knows the difference between playtime and downtime.
				</p>
				<p>
					Tearrible Mediums provide you with hours of playtime with
					your pup, while Collagen Stix give your pup something
					natural and digestible to chew on when you don't have time
					to play (or you just want to catch up on the last episode of
					your favorite show in peace).
				</p>
			</>
		),
	},
	secondaryHeadline: {
		boneyard: "Perfect for Medium and Large Dogs!",
	},
};

export const underworld = {
	description: [
		<h5 style={{ marginTop: "16px" }}>TEARRIBLE VOODOO</h5>,
		"14” tall, 2 detachable arms, 2 detachable legs, 2 detachable ears, and detachable head. Removable squeaker.",
		"Industrial-strength hook and loop fastener (aka “Velcro”) on limbs provides enough resistance to simulate that of a regularly-stitched toy, and allows your dog to experience the satisfaction of “dismembering their prey” by pulling the limbs off the toy, without actually destroying it.",
		<p>
			Tearribles are interactive toys that will provide you and your dog
			with hours of fun, bonding time, and give your dog the exercise and
			mental stimulation he/she needs to minimize destructive behaviors
			which result from boredom and lack of exercise.{" "}
			<span style={{ fontWeight: "bold" }}>
				Tearribles are not a chew toy.
			</span>
		</p>,
		<h5>Underworld Limited Edition Candle</h5>,
		<p>8 oz Coconut Soy Wax candle</p>,
		<p>Burn Time: 45h</p>,
		<p>
			Our Limited Edition, Tearribles Exclusive Underworld candle starts
			with delicate saffron threads, sweet anise, and a refreshing hint of
			eucalyptus. At its core, luscious black currants and blackberries
			mingle with earthy basil, while dark musk, tonka bean, fennel, and
			amber take the fragrance to new depths…way past 6 ft under. These
			candles are made exclusively for Tearribles VIPs from coconut soy
			wax by our good friends at Golden Ginko Candles, and have a 45 hour
			burning time. So whether you have last-minute company, or you just
			want to take a deep dive into the unknown this Halloween, the
			Underworld candle will be sure to take care of the job without
			releasing any harmful fumes.
		</p>,
	],
	bulletPoints: [],
	sizeDescription: {
		voodoo_candle: (
			<>
				<p>
					Included: Tearrible Tiny and Tearrible Medium, and a 10 pack
					of Baloomba 6 inch Thin Collagen Sticks. This combo is the
					perfect tool to help you turn your "super chewer" into a dog
					that knows the difference between playtime and downtime.
				</p>
				<p>
					Tearrible Mediums provide you with hours of playtime with
					your pup, while Collagen Stix give your pup something
					natural and digestible to chew on when you don't have time
					to play (or you just want to catch up on the last episode of
					your favorite show in peace).
				</p>
			</>
		),
	},
	secondaryHeadline: {
		voodoo_candle: "Perfect for Medium and Large Dogs!",
	},
};
